/**
 * Advanced example demonstrating all core MobX constructs.
 */
import { makeAutoObservable } from "mobx";

class AuthStore {
  // ====================================================
  // State
  // ====================================================
  user = null;
  count = 0;
  error = null;
  loading = false;
  loginModal = false;
  signupModal = false;
  forgotModal = false;
  verificationToken = null;
  referralCode = "empty";
  promotionTag = "empty";

  constructor() {
    makeAutoObservable(this);
  }

  // ====================================================
  // Computed views

  // ====================================================
  // While MobX promotes OOP, we can still benefit from using FP where it's appropriate

  // ====================================================
  // Actions
  // ====================================================

  toggleFilter = () => {
    this.filter = !this.filter;
  };

  setLoginModal = () => {
    this.loginModal = !this.loginModal;
    this.signupModal = false;
    this.forgotModal = false;
  };
  setSignupModal = () => {
    this.loginModal = false;
    this.signupModal = !this.signupModal;
    this.forgotModal = false;
  };
  setForgotModal = () => {
    this.loginModal = false;
    this.signupModal = false;
    this.forgotModal = !this.forgotModal;
  };

  closeAllModals = () => {
    this.loginModal = false;
    this.signupModal = false;
    this.forgotModal = false;
  };
  getVerificationToken = (token) => {
    this.verificationToken = token;
  };

  setReferralCode = (reference) => {
    this.referralCode = reference;
  };

  setPromotionTag = (reference) => {
    this.promotionTag = reference;
  };
}

export default new AuthStore();
