export const PUBLIC_PATHS = {
  LANDING: "/",
  LANDINGDATA: "/:location",
  LOGIN: "/auth/login",
  AUTH: "/auth",
  SIGNUP: "/auth/signup",
  REFER_SIGNUP: "/auth/signup/referralCode/:referral_code/:promotion?",
  RESET_PASS: "/auth/reset-password",
  PASS_OTP: "/auth/confirm-password-otp",
  CHANGE_PASS: "/auth/change-password",
  OFFLINE: "/offline",
  PUBLIC_PACKAGES_PARTNERSHIP: "/packages/location/:type/:referral_code",
  PUBLIC_PACKAGES: "/packages/location/:type",
  PACKAGES_DETAIL: "/packages/:id/:couponCode?",
  GALLERY: "/gallery",
};

export const PRIVATE_PATHS = {
  DASHBOARD: "/dashboard",
  PACKAGES: "/packages",
  NOT_FOUND: "/404",
  OFFLINE: "/offline",
  PACKAGES_DETAILS: "/packages/:id/:couponCode?",
  TRIPS: "/trips",
  CHAT: "/chat",
  WISHLIST: "/wishlist",
  TRANSACTION: "/transactions",
  ESTIMATE: "estimate",
  LEGAL: "legal/terms",
  SUPPORT: "/support",
  TERMS: "terms",
  REFER: "/referal",
  COUPON: "/coupons",
  CUSTOMTRIP: "/customtrips",
  TRIPVAULT: "/tripvault",
  PLANTRIP: "/plan-trip",
  POLICY: "legal/policy",
  PROFILE: "profile",
  SUPPORT: "/support",
  PLAN_TRIP: "/plan-trip",
};
