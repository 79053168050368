import React, { useState } from "react";
import { ReactComponent as BackArrow } from "../../assets/img/modal-back-arrow.svg";
import { ReactComponent as Close } from "../../assets/img/close-modal.svg";
import Fail from "assets/icons/fail.png";
import Success from "../../assets/img/success.png";
import { ReactComponent as InfoFill } from "assets/icons/info-fill.svg";

import { Button, RadioBtn } from "../buttons";
import { OtpInput } from "../inputs";
import { ModalType } from "../../constants/modalType";
import FlutterWavePayment from "../../utils/flutterwave.util";
import { BsChevronLeft } from "react-icons/bs";
import {
  PAY_WITH_CARD_MUTATION,
  SEND_OTP,
  WIRE_TRANSFER,
  TRANSFER_FUNDS_MUTATION,
} from "GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import Timer from "utils/timer";
import { errorToast, successToast, warningToast } from "components/toast/toast";
import { LOAD_SAVINGSACCOUNT, LOAD_WALLET } from "GraphQL/Queries";
import AtmCard from "components/card/AtmCard";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ButtonWrapper } from "utils/paypal.util";
import { images } from "constants";
import { DASHBOARD } from "router/router";
import { useNavigate } from "react-router-dom";
import Payment from "utils/stripe/payment";
import { formatter } from "utils/helpers";
import { observer } from "mobx-react-lite";
import vaultStore from "stores/vaultStore";
import { toJS } from "mobx";

const IntAddFunds = ({
  onClose,
  modalType,
  loadCardDetails,
  firstName,
  lastName,
  email,
  id,
  phoneNumber,
  exactTrip,
  loading,
  triggerVault,
  payAll,
}) => {
  const { currentAccount } = vaultStore;
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [addFundsStep, setAddFundsStep] = useState(payAll ? 1 : 0);
  const [initiateStripePayment, setInitiateStripePayment] = useState(false);
  const [wireShow, setWireShow] = useState(false);

  const minimum_amount =
    exactTrip?.balance == 0
      ? Math.ceil(parseInt(exactTrip?.savings_goal) * 0.3)
      : 0;

  const [addFundsAmount, setAddFundsAmount] = useState(
    payAll ? exactTrip?.savings_goal : minimum_amount
  );

  function roundUpTwoDecimals(num) {
    return Math.ceil(num * 100) / 100;
  }

  // For Stripe

  const amount = parseFloat(addFundsAmount) || 0;
  const transactionFee = roundUpTwoDecimals(amount * 0.029 + 0.3);
  const totalCharge = Number(amount + transactionFee);

  const totalStripeAmount = roundUpTwoDecimals(
    amount + totalCharge * 0.029 + 0.3
  );

  const [fundsConfirmed, setFundsConfirmed] = useState({
    status: false,
    amount: "",
  });

  const [primaryCard, setPrimaryCard] = useState({
    trip_vault_id: exactTrip?.id,
    primary_card: {
      id: exactTrip?.primary_card ? exactTrip?.primary_card.id : null,
      token: exactTrip?.primary_card ? exactTrip?.primary_card.token : null,
    },
  });
  const [paymentOtp, setPaymentOtp] = useState("");
  const [depositSource, setDepositSource] = useState(null);

  function handleChange(e) {
    setAddFundsAmount(e.target.value);
  }

  function handleOtpChange(res) {
    setPaymentOtp(res);
  }

  function handleRadioChange(id, token) {
    setPrimaryCard({ ...primaryCard, primary_card: { id: id, token: token } });
  }

  const [transfer] = useMutation(TRANSFER_FUNDS_MUTATION, {
    refetchQueries: [{ query: LOAD_WALLET }, { query: LOAD_SAVINGSACCOUNT }],
  });
  const [createOtp] = useMutation(SEND_OTP);
  const [verifyOtp] = useMutation(PAY_WITH_CARD_MUTATION, {
    refetchQueries: [{ query: LOAD_WALLET }, { query: LOAD_SAVINGSACCOUNT }],
  });
  const [wireTransfer, { loading: wiring }] = useMutation(WIRE_TRANSFER, {
    refetchQueries: [{ query: LOAD_WALLET }, { query: LOAD_SAVINGSACCOUNT }],
  });

  const debitFromWallet = () => {
    setSubmitting(true);

    transfer({
      variables: {
        source_current_account_id: currentAccount.id,
        transfer_amount: addFundsAmount,
        destination_savings_account_id: exactTrip.id,
        user_id: id,
      },
    })
      .then((response) => {
        if (response.data.transfer.__typename === "BadRequest") {
          warningToast("Warning", response.data.transfer.message);
        }
        if (response.data.transfer.__typename === "TransferResultSuccess") {
          successToast("Success", "Transfer successful");
          setFundsConfirmed({ status: true, amount: addFundsAmount });
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const sendPaymentOtp = (resend) => {
    !resend && setSubmitting(true);

    createOtp({})
      .then((response) => {
        if (response.data.createOtp) {
          successToast("Success", response.data.createOtp);
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured");
      })
      .finally(() => {
        setSubmitting(false);
        !resend && setAddFundsStep(4);
      });
  };

  const confirmPaymentOtp = () => {
    setSubmitting(true);

    verifyOtp({
      variables: {
        otp: paymentOtp,
        token: primaryCard.primary_card.token,
        amount: parseInt(addFundsAmount),
        transaction_type:
          modalType === ModalType.ADDFUNDS_VAULT
            ? "SAVINGS_ACCOUNT_DEPOSIT"
            : "CURRENT_ACCOUNT_DEPOSIT",
        account_id:
          modalType === ModalType.ADDFUNDS_VAULT
            ? exactTrip.id
            : currentAccount.id,
      },
    })
      .then((response) => {
        if (response.data.verifyOtp.__typename === "BadRequest") {
          warningToast("Warning", response.data.verifyOtp.message);
        }
        if (response.data.verifyOtp.__typename === "Success") {
          successToast("Success", "Deposit successful");
          setFundsConfirmed({ status: true, amount: addFundsAmount });
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const paywithWire = () => {
    wireTransfer({
      variables: {
        savings_account_id: exactTrip?.id,
        transaction_type:
          modalType === ModalType.ADDFUNDS_VAULT
            ? "SAVINGS_ACCOUNT_DEPOSIT"
            : "CURRENT_ACCOUNT_DEPOSIT",
        account_id:
          modalType === ModalType.ADDFUNDS_VAULT
            ? exactTrip.id
            : currentAccount.id,
      },
    })
      .then((response) => {
        if (response?.data?.createWireTransfer?.__typename === "BadRequest") {
          warningToast("Warning", response?.data?.createWireTransfer?.message);
        }
        if (response?.data?.createWireTransfer?.__typename === "Success") {
          setWireSuccess(true);
        }
      })
      .catch((error) => {
        errorToast("Error", error ? error?.message : "An error occured");
      });
  };

  const triggerFlwPayment = (
    <FlutterWavePayment
      amount={addFundsAmount}
      text="Fund Wallet"
      title="TravelBay"
      description="Add Funds To Your TravelBay Wallet"
      isBlue
      phoneNumber={phoneNumber}
      email={email}
      firstName={firstName}
      lastName={lastName}
      id={id}
      setFundsConfirmed={setFundsConfirmed}
      fundsConfirmed={fundsConfirmed}
      triggerVault={triggerVault}
      meta={
        modalType === ModalType.ADDFUNDS_VAULT
          ? {
              savings_account_id: exactTrip.id,
              transaction_type: "SAVINGS_ACCOUNT_DEPOSIT",
            }
          : {
              current_account_id: currentAccount.id,
              transaction_type: "CURRENT_ACCOUNT_DEPOSIT",
            }
      }
      disabled={
        !addFundsAmount?.length || addFundsAmount.trim() === "" || loading
      }
      loading={loading}
    />
  );

  const accType = sessionStorage.getItem("account_type");
  const [wireSuccess, setWireSuccess] = useState(false);

  return (
    <div>
      {addFundsStep === 0 && !fundsConfirmed.status && (
        <div>
          <div className="flex justify-between">
            <span className="text-[#093549] font-bold text-[22px]">
              Add Funds
            </span>
            <span onClick={onClose} className="cursor-pointer ">
              <Close />
            </span>
          </div>

          <div className="flex pt-8 pb-1 space-y-6 flex-col">
            {modalType === ModalType.ADDFUNDS_VAULT ? (
              <span className="font-medium text-[#093549] text-[18px] ">
                How much do you want to add to your
                <span className="text-blue-100 "> {exactTrip.name} </span>
                package vault?
              </span>
            ) : (
              <span className="font-medium text-gray-100 pl-3">
                How much do you want to add to your Wallet?
              </span>
            )}
            <div className="flex gap-4 h-[120px] w-full">
              <div className="flex flex-col gap-y-2 w-full">
                <p className="text-gray-100 font-medium text-[14px]">
                  Amount {accType?.includes("NIGERIAN") ? "(NGN)" : "(USD)"}{" "}
                </p>
                <div className="flex gap-x-2 border-b-[1.5px] border-black-100/10 w-full">
                  <span className="text-2xl text-[#093549] font-bold ">
                    {accType?.includes("NIGERIAN") ? "₦" : "$"}{" "}
                  </span>
                  <input
                    className="outline-none placeholder:text-gray-60 font-medium text-[#093549] text-2xl placeholder:text-2xl "
                    placeholder="0"
                    onChange={handleChange}
                    name="addFundsAmount"
                    required
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>

          {modalType === ModalType.ADDFUNDS_VAULT && minimum_amount > 0 && (
            <div className="bg-[#E9F6FC] flex items-start gap-1 text-[14px] text-[#475467] w-full p-2 sm:p-4 rounded-[4px] mb-4">
              {" "}
              <span className="p-1">
                <InfoFill />{" "}
              </span>
              <span className="">
                You are obliged to pay at least{" "}
                <span className="font-medium text-[#093549]">
                  {formatter.format(
                    Math.ceil(parseInt(exactTrip?.savings_goal) * 0.3)
                  )}
                </span>{" "}
                off your package fee for it to be permanently added to your trip
                vault.
              </span>
            </div>
          )}

          <div>
            {modalType === ModalType.ADDFUNDS_VAULT && (
              <Button
                text={"Fund Your Trip"}
                isBlue
                disabled={
                  minimum_amount > addFundsAmount ||
                  !addFundsAmount?.length ||
                  addFundsAmount.trim() === "" ||
                  loading
                }
                onClick={() => setAddFundsStep(addFundsStep + 1)}
              />
            )}

            {modalType === ModalType.ADDFUNDS_WALLET &&
              (loadCardDetails?.length === 0 ? (
                accType?.includes("NIGERIAN") ? (
                  triggerFlwPayment
                ) : (
                  <Button
                    isBlue
                    text="Next"
                    disabled={
                      !addFundsAmount?.length ||
                      addFundsAmount.trim() === "" ||
                      loading
                    }
                    onClick={() => setAddFundsStep(addFundsStep + 1)}
                  />
                )
              ) : (
                <Button
                  isBlue
                  text="Next"
                  disabled={
                    !addFundsAmount?.length ||
                    addFundsAmount.trim() === "" ||
                    loading
                  }
                  onClick={() => setAddFundsStep(addFundsStep + 2)}
                />
              ))}
          </div>
        </div>
      )}

      {!wireSuccess &&
        !wireShow &&
        !initiateStripePayment &&
        addFundsStep === 1 &&
        !fundsConfirmed.status && (
          <div>
            <div className="flex gap-x-6 items-center">
              <span
                onClick={() => {
                  setAddFundsStep(addFundsStep - 1);
                }}
                className="cursor-pointer "
              >
                <BsChevronLeft size={20} fill="#5D6D8F" />
              </span>
              <span className="text-[#093549] font-bold text-[22px]">
                Add Funds
              </span>
            </div>

            <div className="flex py-8 space-y-12 flex-col">
              <div className="text-[#093549] font-medium text-[18px]">
                Where do you want to withdraw this money from?
              </div>

              <div className="flex flex-col space-y-8">
                {modalType === ModalType.ADDFUNDS_VAULT && (
                  <RadioBtn
                    selected={depositSource === "WALLET" ? true : false}
                    onClick={() => {
                      setDepositSource("WALLET");
                    }}
                    text={"Travelbay wallet"}
                  />
                )}

                <RadioBtn
                  selected={depositSource === "WIRE" ? true : false}
                  onClick={() => {
                    setDepositSource("WIRE");
                  }}
                  text={"Pay with wire transfer"}
                />

                <div>
                  <RadioBtn
                    selected={depositSource === "STRIPE" ? true : false}
                    onClick={() => {
                      setDepositSource("STRIPE");
                    }}
                    text={"Pay with Stripe"}
                  />

                  {depositSource === "STRIPE" ? (
                    <div className="mt-4">
                      <h4 className="uppercase font-bold text-blue-90 text-sm">
                        Checkout summary
                      </h4>

                      <div className="my-2 flex justify-between items-center">
                        <span className="text-sm text-[#667085] block">
                          Amount to be added
                        </span>
                        <span className="font-bold text-blue-90 text-sm">
                          ${amount}
                        </span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-sm text-[#667085] block">
                          Transaction fees
                        </span>
                        <span className="font-bold text-blue-90 text-sm">
                          ${transactionFee}
                        </span>
                      </div>

                      {/* <div className="flex justify-between items-center">
                        <span className="text-sm text-[#667085] block">
                          Ttal Charge Y
                        </span>
                        <span className="font-bold text-blue-90 text-sm">
                          ${totalCharge}
                        </span>
                      </div> */}

                      <div className="mt-4 pt-4 border-t border-black-100/10 flex justify-between items-center">
                        <span className="text-base font-medium text-[#667085] block">
                          Total Price
                        </span>
                        <span className="font-bold text-blue-90 text-base">
                          ${totalStripeAmount}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="text-center font-medium text-[#5D6D8F]">
                      You can also pay using
                    </div>
                    <PayPalScriptProvider
                      options={{
                        "client-id":
                          "AU9WS3XVb4M4w9PHl6XskCQv_xFrbSCeqqc13J_TDBKwD-r6Ke71gkBltan0Zj2z5CyQ-OBbKg2gS7g1",
                        components: "buttons",
                        currency: "USD",
                      }}
                    >
                      <ButtonWrapper
                        exactTrip={exactTrip}
                        id={id}
                        modalType={modalType}
                        email={email}
                        currentAccount={currentAccount}
                        amount={addFundsAmount}
                        showSpinner={false}
                        setFundsConfirmed={setFundsConfirmed}
                      />
                    </PayPalScriptProvider> */}
              </div>

              <div className="mt-12">
                {depositSource === "STRIPE" ? (
                  <Button
                    onClick={() =>
                      depositSource === "STRIPE"
                        ? setInitiateStripePayment(true)
                        : setAddFundsStep(3)
                    }
                    disabled={depositSource ? false : true}
                    isBlue
                    text={"Proceed to pay"}
                  />
                ) : (
                  <div>
                    <Button
                      onClick={() =>
                        depositSource === "WIRE"
                          ? setWireShow(true)
                          : setAddFundsStep(3)
                      }
                      disabled={depositSource ? false : true}
                      isBlue
                      text={"Proceed to pay"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      {wireShow && !wireSuccess && (
        <div>
          <div>
            <div className="flex gap-x-6 items-center">
              <span
                onClick={() => setWireShow(false)}
                className="cursor-pointer "
              >
                <BsChevronLeft size={20} fill="#5D6D8F" />
              </span>
              <span className="text-[#093549] font-bold text-[22px]">
                Wire Transfer
              </span>
            </div>

            <div className="mt-[16px] text-[15px] text-[#093549]">
              For Wire transactions, make transfer into the account details
              below
            </div>

            <div className="mt-[16px] text-[14px]">
              <span className="text-[#06202C] mb-[16px] font-bold text-[17px]">
                Beneficiary
              </span>
              <div className="flex mt-[4px] items-center font-medium justify-between">
                <span className="text-[#5D6D8F]">Beneficiary Name</span>
                <span className="text-[#093549]">Tbay Africa Inc.</span>
              </div>

              <div className="flex mt-[4px] items-center font-medium justify-between">
                <span className="text-[#5D6D8F]">Account Number</span>
                <span className="text-[#093549]">117067085804</span>
              </div>

              <div className="flex mt-[4px] items-start font-medium justify-between">
                <span className="text-[#5D6D8F]">Beneficiary Address</span>
                <span className="text-[#093549] text-end w-[50%]">
                  220 East, 23rd Street, Suite 400. New York city, NY 10010
                </span>
              </div>
            </div>

            <div className="mt-[16px] text-[14px]">
              <span className="text-[#06202C] text-[17px] mb-[16px] font-bold">
                Receiving Bank Details
              </span>

              <div className="flex  mt-[4px] items-center font-medium justify-between">
                <span className="text-[#5D6D8F]">ABA Routing Number</span>
                <span className="text-[#093549]">103913434</span>
              </div>

              <div className="flex  mt-[4px] items-center font-medium justify-between">
                <span className="text-[#5D6D8F]">Bank Name</span>
                <span className="text-[#093549]">Regent Bank</span>
              </div>
            </div>

            <div className="mt-[16px] text-[14px]">
              <span className="text-[#06202C] mb-[16px] font-bold text-[17px]">
                Additional Details
              </span>

              {modalType === ModalType.ADDFUNDS_VAULT ? (
                <div className="flex items-center font-medium justify-between">
                  <span className="text-[#5D6D8F]">Trip Vault ID</span>
                  <span className="text-[#093549]">{exactTrip.id} </span>
                </div>
              ) : (
                <div className="flex items-center font-medium justify-between">
                  <span className="text-[#5D6D8F]">Wallet ID</span>
                  <span className="text-[#093549]">{currentAccount.id} </span>
                </div>
              )}
            </div>

            <div className="mt-[24px] text-[14px] text-[#093549] bg-[#E5F4FB] rounded-[8px] p-[12px]">
              <span className="font-bold">Instructions</span>
              <br />
              <span className="mt-[4px]">
                Kindly ensure you include the following in your description when
                making a wire transfer:
              </span>

              <div className="flex gap-[8px] items-center">
                <span>•</span>
                <span>Email Address</span>
              </div>

              <div className="flex gap-[8px] items-center">
                <span>•</span>
                <span>Purpose e.g: Trip Vault or Wallet Deposit</span>
              </div>

              <div className="flex gap-[8px] items-center">
                <span>•</span>
                <span>Trip Vault ID</span>
              </div>

              <div>
                <span>
                  You can separate this using a “comma” in the description
                </span>
                <br />
                <span className="font-medium">
                  Thank you for trusting travelbay!
                </span>
              </div>
            </div>

            <div className="mt-[36px] flex flex-col gap-[16px]">
              <Button
                // onClick={() => setWireSuccess(true)}
                onClick={paywithWire}
                loading={wiring}
                isBlue
                text="I have made this transfer"
              />
              <Button
                onClick={() => setWireShow(false)}
                className="border gap-[13px] flex flex-row-reverse items-center justify-center text-[14px] rounded-lg border-blue-100 h-12 hover:bg-[#d2ecf8] hover:border-[2px] font-medium w-[129px]"
                text="Cancel"
              />
            </div>
          </div>
        </div>
      )}

      {initiateStripePayment && !fundsConfirmed.status && (
        <div className="w-full">
          <div className="flex gap-x-6 items-center">
            <span
              onClick={() => setAddFundsStep(addFundsStep - 2)}
              className="cursor-pointer "
            >
              <BsChevronLeft size={20} fill="#5D6D8F" />
            </span>
            <span className="text-[#093549] font-bold text-[22px]">
              Add funds with Stripe
            </span>
          </div>

          <div>
            <Payment
              amount={totalStripeAmount}
              phoneNumber={phoneNumber}
              email={email}
              name={firstName + "" + lastName}
              id={id}
              setFundsConfirmed={setFundsConfirmed}
              disabled={depositSource ? false : true}
              paymentDetails={
                modalType === ModalType.ADDFUNDS_VAULT
                  ? {
                      account_id: exactTrip.id,
                      account_type: "SAVINGS_ACCOUNT_DEPOSIT",
                    }
                  : {
                      account_id: currentAccount.id,
                      account_type: "CURRENT_ACCOUNT_DEPOSIT",
                    }
              }
            />
          </div>
        </div>
      )}

      {wireSuccess && (
        <div className="flex flex-col justify-center items-center">
          <img alt="" src={images.wiresuccess} />
          <div className="my-[8px] text-[#093549] font-bold text-[20px]">
            Thank you!
          </div>
          <div className="text-[#5D6D8F] mb-[36px] text-center">
            Your trip vault would be updated soon. Kindly reach out to us with a
            proof of payment in 24 hours if this doesn’t happen{" "}
          </div>

          <Button
            onClick={() => {
              navigate(DASHBOARD);
              onClose();
            }}
            isBlue
            text="Go to dashboard"
          />
        </div>
      )}
      {addFundsStep === 3 && !fundsConfirmed.status && (
        <div>
          <div className="flex gap-x-6 items-center">
            <span
              onClick={() => setAddFundsStep(addFundsStep - 2)}
              className="cursor-pointer "
            >
              <BsChevronLeft size={20} fill="#5D6D8F" />
            </span>
            <span className="text-[#093549] font-bold text-[22px]">
              Add funds from wallet
            </span>
          </div>

          <div className="flex py-8 space-y-3 flex-col">
            <div className="flex items-center h-[100px] text-center w-full mx-auto border-b-[1.5px] border-black-100/10 px-2">
              <span className="text-2xl text-[#093549] font-bold ">
                {" "}
                {accType?.includes("NIGERIAN") ? "₦" : "$"}
              </span>
              <div className=" border-0 font-medium text-[#093549]/60 text-2xl w-full">
                {currentAccount?.balance} - {addFundsAmount}
              </div>
            </div>
            {currentAccount?.balance - addFundsAmount < 0 && (
              <div className="flex flex-col gap-y-3 justify-center text-center">
                <img
                  src={Fail}
                  alt=""
                  className="w-[50%] max-w-[150px] mx-auto"
                />
                <p className="text-[#093549]/80 text-[20px] font-bold text-center">
                  Insufficient wallet balance
                </p>
                <p className="text-gray-100 text-center text-[14px]">
                  You do not have enough balance in your wallet to complete this
                  transaction. Please fund your wallet and try again
                </p>
              </div>
            )}
          </div>

          <div>
            <Button
              text={"Confirm"}
              isBlue
              disabled={
                !addFundsAmount?.length ||
                addFundsAmount.trim() === "" ||
                loading ||
                submitting ||
                currentAccount?.balance - addFundsAmount < 0
              }
              loading={submitting}
              onClick={() => debitFromWallet()}
            />
          </div>
        </div>
      )}

      {addFundsStep === 2 && !fundsConfirmed.status && (
        <div>
          <span
            onClick={() => setAddFundsStep(addFundsStep - 1)}
            className="cursor-pointer absolute top-3 left-3 "
          >
            <BackArrow />
          </span>
          <div className="flex py-8 space-y-3 flex-col">
            <span className="text-[#093549] font-medium text-lg md:text-2xl pl-3">
              Payment Method
            </span>
            <span className="font-medium text-gray-100 pl-3">
              Select a payment method
            </span>
            <div className=" flex flex-wrap gap-2 justify-start w-[100%] ">
              {loadCardDetails?.map((cardDetail) => {
                return (
                  <div>
                    <AtmCard
                      card_name={cardDetail?.card_name}
                      bank={cardDetail?.bank}
                      last_digit={cardDetail?.last_four_digit}
                      expires={`${cardDetail?.expiry_month} / ${cardDetail?.expiry_year}`}
                      selected={
                        primaryCard?.primary_card?.id === cardDetail?.id
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleRadioChange(cardDetail?.id, cardDetail?.token);
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <Button
              onClick={() =>
                depositSource === "STRIPE"
                  ? setInitiateStripePayment(true)
                  : setAddFundsStep(3)
              }
              disabled={depositSource ? false : true}
              isBlue
              text={"+ Add New Payment"}
            />
            <div></div>
          </div>
          <Button
            isBlue
            disabled={primaryCard.primary_card.id ? false : true}
            text="Send Payment Otp"
            className="text-base font-medium"
            onClick={() => {
              sendPaymentOtp();
            }}
            loading={submitting}
          />
        </div>
      )}

      {addFundsStep === 4 && !fundsConfirmed.status && (
        <div>
          <span
            onClick={() => setAddFundsStep(addFundsStep - 1)}
            className="cursor-pointer absolute top-3 left-3 "
          >
            <BackArrow />
          </span>
          <div className="flex py-8 space-y-3 flex-col">
            <span className="text-[#093549] font-medium text-lg md:text-2xl pl-3">
              Verify payment
            </span>
            <span className="font-medium text-gray-100 pl-3">
              Enter OTP code
            </span>
            <div className=" flex flex-col gap-y-2 justify-start w-[100%] px-4 ">
              <OtpInput
                onChange={handleOtpChange}
                length={"6"}
                inputClass={
                  "text-[#2A2B2C] text-xl font-medium border-[#093549] border-[.5px]  rounded w-[40px] h-[40px] flex  text-center focus:border-[4px] focus:border-blue-50"
                }
                containerClass={"flex w-[100%] justify-around"}
              />
            </div>
            <div className="pl-3">
              <Timer minute={5} className="font-medium" />
            </div>
            <div
              onClick={() => {
                sendPaymentOtp(true);
              }}
              className="pl-3 cursor-pointer text-blue-100 onFocus:text-[#093549]/"
            >
              Resend OTP ?
            </div>
          </div>
          <Button
            isBlue
            text="Submit Otp"
            className="text-base font-medium"
            onClick={() => {
              confirmPaymentOtp();
            }}
            loading={submitting}
          />
        </div>
      )}

      {fundsConfirmed.status && (
        <div className="py-5">
          <span
            onClick={onClose}
            className="cursor-pointer absolute top-3 left-3 "
          >
            <Close />
          </span>
          <div className="flex space-y-4 flex-col items-center text-center font-medium">
            <img src={Success} alt="" className="w-[25%] mb-8 " />
            <span className="w-[70%] text-[#093549] font-medium text-2xl ">
              Success
            </span>
            <span className="text-gray-100 w-[80%]">
              You have added {accType?.includes("NIGERIAN") ? "₦" : "$"}{" "}
              <span>
                {depositSource === "STRIPE" ? amount : fundsConfirmed.amount}
              </span>{" "}
              to your vault{" "}
            </span>
            <Button
              onClick={() => {
                navigate(DASHBOARD);
                // onClose();
              }}
              isBlue
              text={"Go back to dashboard"}
              className="max-w-[450px]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(IntAddFunds);
