import { Close } from "@material-ui/icons";
import { ArithmeticBtn, Button, RadioBtn } from "components/buttons";
import React, { useCallback, useEffect, useState } from "react";

import {
  closestNumber,
  formatter,
  getNumberOfDays,
  isDatePastToday,
} from "utils/helpers";
import { BsChevronLeft } from "react-icons/bs";
import { Input, SelectInput } from "components/inputs";
import moment from "moment";
import { TRIPVAULT_MUTATION } from "GraphQL/Mutations";
import { COUPONS, LOAD_SAVINGSACCOUNT } from "GraphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import Success from "assets/img/success.png";
import { errorToast, successToast, warningToast } from "components/toast/toast";
import { observer } from "mobx-react-lite";
import vaultStore from "stores/vaultStore";
import { ReactComponent as InfoFill } from "assets/icons/info-fill.svg";
import { ModalType } from "constants/modalType";

const AddTripVault = ({
  onClose,
  minimum_travellers,
  number_travellers,
  singlePackageInfo,
  rate_info,
  payAll,
  setIsSubmitting,
  isSubmitting,
}) => {
  const {
    setPackageNumber,
    payingAll,
    setPaying,
    setExactTrip,
    exactTrip,
    paying,
    modalType,
    setModalType,
    showModal,
    setShowModal,
    setPaymentCouponCode,
  } = vaultStore;

  const [vaultStep, setvaultStep] = useState(0);
  const [numberOfPackages, setNumberOfPackages] = useState(
    minimum_travellers ? minimum_travellers : 1
  );
  const [vaultType, setVaultType] = useState("");
  const [packageFrequencyRate, setPackageFrequencyRate] = useState("");

  const [createVaultData, setCreateVaultData] = React.useState({
    package: singlePackageInfo.id,
    autosave: false,
    savings_type: "PACKAGE",
    group_type: vaultType,
    target_date: singlePackageInfo.booking_deadline_date,
    debit_interval: null,
    targetAmount: rate_info.rate * numberOfPackages,
    frequencyAmount: "",
    savingFrequency: "",
    room_rate_id: rate_info.id,
    joint_user_ids: [],
    number_adults: singlePackageInfo.number_adults,
    number_children: singlePackageInfo.number_children,
    number_infants: singlePackageInfo.number_infants,
    number_packages: numberOfPackages,
  });
  const [couponList, setCouponList] = useState([]);
  const [loadingCoupons, setLoadingCoupons] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState({
    couponCode: "",
    percentage: null,
  });

  const [userIds, setUserIds] = useState([]);

  const [createVault] = useMutation(TRIPVAULT_MUTATION, {
    refetchQueries: [{ query: LOAD_SAVINGSACCOUNT }, { query: COUPONS }],
  });
  const couponResponse = useQuery(COUPONS);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCreateVaultData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const payment = useCallback(() => {
    setModalType(ModalType.ADDFUNDS_VAULT);
    setShowModal(true);
    setPaying(false);
  }, [exactTrip]);

  const debit_interval =
    createVaultData.savingFrequency == "DAILY"
      ? "1"
      : createVaultData.savingFrequency === "WEEKLY"
      ? "7"
      : createVaultData.savingFrequency === "MONTHLY"
      ? "30"
      : createVaultData.savingFrequency === "CUSTOM"
      ? createVaultData.debit_interval
      : "0";

  const savingFrequencies = [
    { name: "CUSTOM", days: "" },
    { name: "DAILY", days: "(1 Day)" },
    { name: "WEEKLY", days: "(7 Days)" },
    { name: "MONTHLY", days: "(30 Days)" },
  ];

  useEffect(() => {
    setLoadingCoupons(couponResponse.loading);

    if (couponResponse.data) {
      setCouponList(couponResponse.data.me.user.coupons);
    }
  }, [couponResponse]);

  useEffect(() => {
    if (createVaultData.savingFrequency) {
      const Frequency =
        (debit_interval /
          closestNumber(
            getNumberOfDays(singlePackageInfo.booking_deadline_date),
            debit_interval
          )) *
        (rate_info.rate * numberOfPackages);

      setPackageFrequencyRate(
        Frequency > rate_info.rate * numberOfPackages
          ? formatter.format(rate_info.rate * numberOfPackages)
          : formatter.format(Frequency)
      );
    }
  }, [createVaultData]);

  const availableCoupons = couponList?.filter(
    (item) =>
      item.usedCoupon === false && !isDatePastToday(item.couponId.expiresAt)
  );

  const createTripVault = () => {
    createVaultData.joint_user_ids?.forEach((element) => {
      userIds?.push(element[0].id);
    });

    createVault({
      variables: {
        package: createVaultData.package,
        autosave: createVaultData.autosave,
        group_type: createVaultData.group_type,
        debit_interval: parseInt(createVaultData.debit_interval),
        savings_frequency: createVaultData.savingFrequency,
        savings_type: createVaultData.savings_type,
        room_rate_id: rate_info.id,
        joint_user_ids: userIds,
        number_adults: createVaultData.number_adults,
        number_children: createVaultData.number_children,
        number_infants: createVaultData.number_infants,
        number_packages: parseInt(numberOfPackages),
        payment_type: "INSTALLMENT",
        couponCode: selectedCoupon?.couponCode,
      },
    })
      .then((response) => {
        setPaying(false);

        if (response.data.addTripVault.__typename === "BadRequest") {
          warningToast("Warning", response.data.addTripVault.message);
        }
        if (
          response.data.addTripVault.__typename === "AddSavingsAccountSuccess"
        ) {
          successToast("Success", "Vault successfully created");
          setExactTrip(response.data.addTripVault.savingsAccount);
          payment();
        }
      })
      .catch((error) => {
        setPaying(false);
        if (createVaultData.package === null) {
          errorToast("Error", "Select a Package");
        } else if (!createVaultData.savingFrequency) {
          errorToast("Error", "Select savings frequency");
        } else {
          errorToast("Error", error ? error?.message : "An error occured");
        }
      });
  };

  const handlePayAllSubmit = () => {
    setIsSubmitting(true);
    if (payAll) {
      setPaymentCouponCode(selectedCoupon?.couponCode);
      setPackageNumber(numberOfPackages);
    } else {
      setvaultStep(1);
    }
  };

  const handleNavigate = () => {
    window.location.replace(`/dashboard?scrollToItem=true`);
  };

  return (
    <div>
      {vaultStep === 0 && (
        <div>
          <span
            onClick={onClose}
            className="cursor-pointer absolute top-3 right-3 "
          >
            <Close />
          </span>
          <div className="flex py-8 space-y-6 flex-col">
            <p className="text-blue-200 font-bold text-[20px] md:text-2xl text-center">
              Package Checkout
            </p>

            <div className="text-[#5D6D8F]/70">
              {!minimum_travellers && (
                <p className="text-center">
                  This package is for <span>{number_travellers}</span> travelers
                  per package. Kindly select the number of packages you’d like
                  to purchase.{" "}
                </p>
              )}
              {minimum_travellers && (
                <p className="text-center">
                  A minimum purchase of <span>{minimum_travellers}</span>{" "}
                  packages is required to activate this price offer.
                </p>
              )}
            </div>
            <div className="flex gap-x-6 items-center mx-auto ">
              <ArithmeticBtn
                subtract
                gray
                onClick={() => {
                  if (minimum_travellers) {
                    numberOfPackages > minimum_travellers
                      ? setNumberOfPackages(numberOfPackages - 1)
                      : setNumberOfPackages(minimum_travellers);
                  } else if (!minimum_travellers) {
                    numberOfPackages > 1
                      ? setNumberOfPackages(numberOfPackages - 1)
                      : setNumberOfPackages(1);
                  }
                }}
              />
              <p className="text-blue-200 text-[20px] font-bold">
                {numberOfPackages}
              </p>
              <ArithmeticBtn
                add
                deepBlue
                onClick={() => {
                  setNumberOfPackages(numberOfPackages + 1);
                }}
              />
            </div>

            <div className="flex flex-col gap-y-1 border-y border-black-100/10 py-2">
              <p className="text-[#093549]/90 font-medium">SUMMARY</p>
              <div className="flex justify-between">
                <p className="font-medium text-[#5D6D8F]/70 text-[18px]">
                  {singlePackageInfo.name}
                </p>
                <p className="font-bold text-[#093549]">X{numberOfPackages}</p>
              </div>
            </div>

            {availableCoupons && availableCoupons.length > 0 && (
              <div className="flex flex-col gap-y-1 border-y border-black-100/10 py-2">
                <p className="text-[#093549]/90 font-medium uppercase">
                  Available Coupons
                </p>

                {availableCoupons?.map((item) => (
                  <div className="flex justify-between mb-2">
                    <p className="font-medium text-[#5D6D8F]/70 text-[18px]">
                      {item?.couponId.promotionName}{" "}
                      <span className="text-[#093549]/90">
                        ({item?.couponId.discountPercentage}% OFF)
                      </span>
                    </p>
                    <div
                      onClick={() => {
                        selectedCoupon?.couponCode === item.couponId.couponCode
                          ? setSelectedCoupon({
                              couponCode: "",
                              percentage: null,
                            })
                          : setSelectedCoupon({
                              couponCode: item.couponId.couponCode,
                              percentage: item?.couponId.discountPercentage,
                            });
                      }}
                      className="w-[24px] h-[24px] aspect-square border border-blue-100 rounded-full flex items-center justify-center p-1"
                    >
                      {selectedCoupon?.couponCode ===
                        item.couponId.couponCode && (
                        <div className="w-4 h-4 aspect-square border border-blue-100 bg-blue-100 rounded-full">
                          {" "}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!payAll && (
              <div className="bg-[#E9F6FC] flex items-start gap-1 text-[14px] text-[#475467] w-full p-2 sm:p-4 rounded-[4px]">
                {" "}
                <span className="p-1">
                  <InfoFill />{" "}
                </span>
                <span className="">
                  You are obliged to pay at least{" "}
                  <span className="font-medium text-[#093549]">
                    {formatter.format(
                      Math.ceil(rate_info?.rate * numberOfPackages * 0.3)
                    )}
                  </span>{" "}
                  off your package fee for it to be added into a trip vault.
                </span>
              </div>
            )}

            <div className="flex flex-col gap-2">
              <p className="text-[#344054]">TOTAL PRICE:</p>
              <p className="font-bold text-blue-200 text-[24px]">
                {formatter.format(
                  rate_info?.rate *
                    ((100 - selectedCoupon?.percentage) / 100) *
                    numberOfPackages
                )}
              </p>
            </div>

            <div>
              <Button
                onClick={handlePayAllSubmit}
                isBlue
                loading={isSubmitting}
                text={
                  payAll
                    ? `Proceed to pay- ${formatter.format(
                        rate_info?.rate *
                          ((100 - selectedCoupon?.percentage) / 100) *
                          numberOfPackages
                      )}`
                    : "Proceed to pay now"
                }
              />
            </div>
          </div>
        </div>
      )}

      {vaultStep === 1 && (
        <div>
          <span
            onClick={onClose}
            className="cursor-pointer absolute top-3 right-3 "
          >
            <Close />
          </span>
          <div className="flex py-8 space-y-6 flex-col">
            <p className="text-blue-200 font-bold text-[20px] md:text-2xl text-center">
              Create a new vault
            </p>

            <p className="text-[#093549]/90 text-lg font-medium">
              Who would be saving in this vault?
            </p>
            <div className="flex flex-col gap-y-5">
              <RadioBtn
                selected={vaultType === "PERSONAL" ? true : false}
                onClick={() => {
                  setVaultType("PERSONAL");
                  setCreateVaultData((prevState) => {
                    return {
                      ...prevState,
                      group_type: "PERSONAL",
                    };
                  });
                }}
                text={"Personal vault (Me alone)"}
              />
              <RadioBtn
                disabled
                selected={vaultType === "JOINT" ? true : false}
                onClick={() => {
                  setVaultType("JOINT");
                }}
                text={"Joint trip vault (Me and my friends)"}
              />
            </div>
            <div>
              <Button
                onClick={() => {
                  setvaultStep(2);
                }}
                disabled={vaultType ? false : true}
                isBlue
                text={"Proceed"}
              />
            </div>
          </div>
        </div>
      )}
      {vaultStep === 2 && vaultType === "PERSONAL" && (
        <div>
          <span
            onClick={() => setvaultStep(vaultStep - 1)}
            className="hover:bg-gray-30 font-medium text-[#667085] text-[18px] cursor-pointer flex items-center gap-x-2"
          >
            <BsChevronLeft size={18} fill="#667085" />
            <p>Go back</p>
          </span>
          <div className="flex py-8 space-y-6 flex-col">
            <p className="text-blue-200 font-bold text-[20px] md:text-2xl text-center">
              Create personal vault
            </p>

            <div className="flex flex-col gap-y-5 overflow-auto max-h-[calc(100vh_-_160px)] modal_scroll">
              <div className="flex space-y-4 flex-col ">
                <div className="flex flex-col gap-y-1 ">
                  <p className="text-[14px] text-[#093549]/90 font-medium">
                    Package Title
                  </p>
                  <p className=" text-[#093549]/90 font-bold">
                    {singlePackageInfo?.name}
                  </p>
                </div>

                <SelectInput
                  label="Savings frequency"
                  placeholder={"Anytime"}
                  withLabel
                  name={"savingFrequency"}
                  onChange={handleChange}
                  options={savingFrequencies}
                  value={createVaultData.savingFrequency}
                  required
                  labelClass={"!text-[14px] !font-medium !text-[#093549]/90"}
                  wrapClass={
                    createVaultData.savingFrequency ? "" : "border-red-100"
                  }
                />

                {createVaultData.savingFrequency === "CUSTOM" && (
                  <Input
                    onChange={handleChange}
                    type="number"
                    label={`Debit Interval`}
                    placeholder={"Number of days between debits"}
                    withLabel
                    name="debit_interval"
                    value={createVaultData.debit_interval}
                    days={true}
                    className={
                      createVaultData.debit_interval ? "" : "border-red-100"
                    }
                  />
                )}

                <div className="pl-4 text-gray-100 font-medium">
                  <p>
                    Deadline :{" "}
                    <span className="text-[#344054]/90 font-bold">
                      {moment(singlePackageInfo.booking_deadline_date).format(
                        "ll"
                      )}
                    </span>
                  </p>
                  <p>
                    Depature :{" "}
                    <span className="text-[#344054]/90 font-bold">
                      {moment(singlePackageInfo.departure_date).format("ll")}
                    </span>
                  </p>
                  <p>
                    Return :{" "}
                    <span className="text-[#344054]/90 font-bold">
                      {moment(singlePackageInfo.return_date).format("ll")}
                    </span>
                  </p>
                  <p>
                    Target Amount :{" "}
                    <span className="text-[#344054]/90 font-bold">
                      {formatter.format(rate_info?.rate * numberOfPackages)}
                    </span>
                  </p>
                  {createVaultData.savingFrequency && (
                    <p>
                      Saving Frequency :{" "}
                      <span className="text-[#344054]/90 font-bold ">
                        {packageFrequencyRate}
                        {""} (
                        <span className="lowercase">
                          {createVaultData.savingFrequency}
                        </span>
                        )
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Button
                onClick={() => {
                  createTripVault();
                }}
                isBlue
                text={"Create new vault"}
              />
            </div>
          </div>
        </div>
      )}

      {vaultStep === 3 && (
        <div>
          <span
            onClick={onClose}
            className="cursor-pointer absolute top-3 left-3 "
          >
            <Close />
          </span>
          <div className="flex space-y-8 flex-col items-center text-center py-10">
            <img src={Success} alt="" className="w-[120px] max-w-[25%] " />
            <div>
              <p className=" text-blue-200 font-bold text-[20px]">
                Package vault created successfully
              </p>
              <p className="text-gray-100">
                You have successfully created a personal trip vault
              </p>
              <p className="flex gap-x-5">
                <Button
                  onClick={onClose}
                  className={
                    "font-medium sm:text-base flex items-center justify-center text-blue-100 flex-row-reverse gap-1  px-2 border border-blue-100"
                  }
                  text={"Go back"}
                />
                <Button
                  onClick={() => handleNavigate()}
                  isBlue
                  text={"View vault"}
                />
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(AddTripVault);
