import React, { useEffect, useState, useMemo } from "react";
import { ArrowDownward } from "@material-ui/icons";

import { useQuery } from "@apollo/client";
import { GET_PROMOTION_POST } from "GraphQL/Queries";
import { BiLoaderCircle } from "react-icons/bi";
import Frame1 from "./animations/Frame1";
import Frame2 from "./animations/Frame2";
import Frame3 from "./animations/Frame3";

import { ReactComponent as Logo } from "../.../../../assets/icons/logo.svg";
import { ReactComponent as HandShake } from "../.../../../assets/icons/handshake.svg";
import { ReactComponent as ArrowRight } from "../.../../../assets/icons/arrow-right.svg";

import authStore from "stores/authStore";
import { observer } from "mobx-react-lite";

const LoadingSpinner = () => (
  <div className="h-screen flex items-center justify-center">
    <BiLoaderCircle size={120} className="mx-auto animate-spin-slow" />
  </div>
);

const MobileArrow = ({ handleClick }) => (
  <div className="lg:hidden absolute z-[999] left-[50%] -translate-x-1/2 bottom-[30%]">
    <div
      onClick={handleClick}
      className="arrow bg-[white] flex mt-[20px] lg:hidden flex-col justify-center items-center h-[55px] w-[55px] rounded-full border border-blue-100"
    >
      <ArrowDownward />
    </div>
  </div>
);

const SideBar = ({ handleClick }) => {
  const { referralCode, promotionTag } = authStore;
  const [isCustom, setIsCustom] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [secDone, setSecDone] = useState(false);
  const [reference, setReference] = useState("");
  const [promo, setPromo] = useState("");
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    setReference(referralCode);
    setPromo(promotionTag);
  }, [promotionTag, referralCode]);

  // Query handling
  const skip =
    promo === "empty" || !promo || !reference || reference === "empty";
  const { data, loading } = useQuery(GET_PROMOTION_POST, {
    variables: { couponCode: reference },
    skip,
  });

  const promoData = useMemo(() => {
    return data?.getPromotionPost?.promotionPost;
  }, [data]);

  // Animation effects
  useEffect(() => {
    if (secDone) {
      const timer = setTimeout(() => {
        setInitDone(false);
        setIsCustom(false);
        setSecDone(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [secDone]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide(initDone ? 1 : 0);
    });
    return () => clearInterval(interval);
  }, [initDone]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="relative lg:h-screen">
      <ContentWrapper
        promo={promo}
        data={data}
        promoData={promoData}
        renderContent={() => (
          <SideBarContent
            slide={slide}
            isCustom={isCustom}
            setIsCustom={setIsCustom}
            initDone={initDone}
            setInitDone={setInitDone}
            setSecDone={setSecDone}
            secDone={secDone}
            handleClick={handleClick}
          />
        )}
        renderPromoContent={() => (
          <PromotionContent promoData={promoData} handleClick={handleClick} />
        )}
      />
    </div>
  );
};

const ContentWrapper = ({
  promo,
  data,
  promoData,
  renderContent,
  renderPromoContent,
}) => {
  if (
    promo === "promotion" &&
    data?.getPromotionPost?.__typename === "BadRequest"
  ) {
    return renderContent();
  }

  if (promo === "promotion" && promoData) {
    return renderPromoContent();
  }

  return renderContent();
};

// slide
const SideBarContent = ({
  slide,
  isCustom,
  setIsCustom,
  initDone,
  setInitDone,
  setSecDone,
  secDone,
  handleClick,
}) => (
  <div className="bg-[#F7F7F8] relative py-20 lg:py-0 h-full sm:flex overflow-x-hidden xs:px-[37px] sm:px-[57px] flex-col items-center justify-center">
    <SlideContent
      slide={slide}
      isCustom={isCustom}
      setIsCustom={setIsCustom}
      initDone={initDone}
      setInitDone={setInitDone}
      setSecDone={setSecDone}
      secDone={secDone}
    />
    <TextContent slide={slide} />
    <SlideIndicator slide={slide} />
    <MobileArrow handleClick={handleClick} />
  </div>
);

const PromotionContent = ({ promoData, handleClick }) => {
  if (promoData?.fullBannerImage) {
    return (
      <FullBannerPromotion promoData={promoData} handleClick={handleClick} />
    );
  }
  return (
    <BannerAndIconPromotion promoData={promoData} handleClick={handleClick} />
  );
};

const FullBannerPromotion = ({ promoData, handleClick }) => (
  <div
    style={{
      backgroundColor: `${promoData?.fullBannerBackgroundColor}`,
    }}
    className="w-full h-full relative"
  >
    <img
      className="object-contain h-full w-full"
      src={promoData?.fullBannerImage}
      alt=""
    />
    <div className="lg:hidden absolute z-[999] left-[50%] -translate-x-1/2 bottom-[10%]">
      <div
        onClick={handleClick}
        className="arrow bg-[white] flex mt-[20px] lg:hidden flex-col justify-center items-center h-[55px] w-[55px] rounded-full border border-blue-100"
      >
        <ArrowDownward />
      </div>
    </div>
  </div>
);

const BannerAndIconPromotion = ({ promoData, handleClick }) => (
  <div
    style={{
      backgroundImage: `url(${promoData?.bannerImage})`,
    }}
    className="bg-top bg-cover bg-no-repeat bg-[#EDEDEDD9] bg-blend-overlay w-full h-full flex flex-col gap-y-24 lg:gap-y-0 justify-between relative"
  >
    <div className="pt-4 md:pt-8 flex justify-center items-center gap-x-3">
      <Logo className="w-24 md:w-32" />
      <HandShake />
      {promoData?.partnersIconImage ? (
        <div className="h-12 w-12 bg-white-100 p-1 rounded-full relative overflow-hidden">
          <img
            className="h-full w-full object-cover rounded-full "
            src={promoData?.partnersIconImage}
            alt="travelbay logo"
          />
        </div>
      ) : (
        <span className="block font-bold text-base md:text-xl text-blue-90">
          {promoData?.partnersName}
        </span>
      )}
    </div>

    <div className="px-4 md:px-8 lg:px-14">
      <div className="px-4 py-3 mb-4 bg-white-100 border-2 border-dashed border-blue-100 rounded-full w-fit">
        <span className="font-bold text-sm text-blue-90">
          Discount Partnership with {promoData?.partnersName}
        </span>
      </div>
      <h1 className="bebas-neue-regular font-bold text-3xl md:text-4xl xl:text-[56px] lg:leading-tight text-black uppercase">
        Get the chance to explore Africa with a special discount offer!
      </h1>
    </div>

    <div className="flex items-center gap-x-4 lg:gap-x-8 justify-between relative bg-gold-100 py-8 px-5 md:px-10">
      <div className="flex-1 xl:pr-10">
        <h2 className="border-b-2 border-black-100 pb-2 font-black text-black text-lg lg:text-3xl xl:text-5xl uppercase">
          Up to {promoData?.couponId?.discountPercentage}% off
        </h2>
        <p className="pt-2 text-[#282210] font-medium text-base lg:text-xl">
          Coupon code:{" "}
          <span className="font-black">{promoData?.couponId?.couponCode}</span>
        </p>
      </div>
      <div>
        <button
          disabled
          className="flex text-sm lg:text-base px-4 lg:px-6 py-2 lg:py-4 w-fit bg-blue-100 text-[white] font-bold rounded-lg"
        >
          Book now <ArrowRight />
        </button>
      </div>
    </div>

    <div className="lg:hidden absolute z-[999] left-[50%] -translate-x-1/2 bottom-[20%]">
      <div
        onClick={handleClick}
        className="arrow bg-[white] flex mt-[20px] lg:hidden flex-col justify-center items-center h-[55px] w-[55px] rounded-full border border-blue-100"
      >
        <ArrowDownward />
      </div>
    </div>
  </div>
);

const SlideContent = ({
  slide,
  isCustom,
  setIsCustom,
  initDone,
  setInitDone,
  setSecDone,
  secDone,
}) => (
  <>
    <div
      className={`w-full flex flex-col items-center justify-center transition-transform duration-1000 transform ${
        slide === 0 ? "translate-x-0" : "translate-x-full"
      }`}
    >
      {!isCustom && !initDone && (
        <Frame1 isCustom={isCustom} setIsCustom={setIsCustom} />
      )}
      {isCustom && !initDone && <Frame2 setInitDone={setInitDone} />}
    </div>
    <div
      className={`w-full flex flex-col items-center justify-center transition-transform duration-1000 transform ${
        slide === 1 ? "translate-x-0" : "translate-x-full origin-left"
      }`}
    >
      {initDone && <Frame3 setSecDone={setSecDone} secDone={secDone} />}
    </div>
  </>
);

const TextContent = ({ slide }) => (
  <div className="relative lg:min-h-[16vh] xs:min-h-[16vh] xs:overflow-y-hidden sm:w-[600px] lg:w-[650px] overflow-x-hidden">
    <SlideText
      slide={slide}
      index={0}
      title="Access packages & create custom trips"
      description="Explore ready made packages from the best deals in the market for you. Tailor your perfect adventure with our custom trip creation tool"
    />
    <SlideText
      slide={slide}
      index={1}
      title="Customize a payment plan for your dream trip"
      description="Choose a payment frequency that works for you to make your dream trip happen. You can pay in installments before the departure date is close."
    />
  </div>
);

const SlideText = ({ slide, index, title, description }) => (
  <div
    className={`flex bg-[#F7F7F8] ${
      index === 0 ? "left-0" : "absolute"
    } right-0 ${index === 0 ? "absolute" : ""} top-0 pb-[${
      index === 0 ? "0" : "30"
    }px] xs:px-[37px] sm:px-[57px] flex-col justify-center items-center text-center transition-transform duration-1000 transform ${
      slide === index ? "translate-x-0" : "translate-x-full"
    }`}
  >
    <div className="text-[18px] font-bold text-[#093549]">{title}</div>
    <div className="mt-[4px] text-[15px] text-[#475467] text-center">
      {description}
    </div>
  </div>
);

const SlideIndicator = ({ slide }) => (
  <div className="flex gap-[15px] justify-center items-center">
    {[0, 1].map((index) => (
      <div key={index}>
        <div
          className={`${
            index === slide ? "bg-blue-100" : "bg-[#D4DFE4]"
          } rounded-full w-[87px] h-[7px]`}
        />
      </div>
    ))}
  </div>
);

export default observer(SideBar);
